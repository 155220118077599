<template>
    <div class="main">
        <div class="doc-content">
            <p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>更新日期：</span>2022年03月11日
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                开发者名称：石家庄守正企业管理咨询有限公司
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                应用名称：<span>人康</span>APP
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                提示条款
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                欢迎使用有石家庄守正企业管理咨询有限公司<span>（以下简称</span>“守正”或“我们”）提供的产品服务！
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                守正将<span>依据相关法律法规和技术规范收集和使用您的个人信息，并采取相应的安全措施，尽力保护用户（以下简称</span>“您”）的个人隐私安全可控。 有鉴于此，我们制定本《守正隐私政策》（以下简称本政策）并提醒您：
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                本政策适用于守正科技产品及服务。在使用守正科技产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体标识的条款，请您重点阅读。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span style="color:#E53333;">请您注意，只有在您确认同意后，我们才会开始收集、使用、处理和存储您的个人信息。</span><span style="color:#E53333;">-旦您勾选“同意”本政策， 即表示您已同意我们按照本政策处理您的个人信息。如果您不同意我们收集您的任何个人信息，请立即停止使用并退出</span><span style="color:#E53333;">人康</span><span style="color:#E53333;">APP。</span>
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>如您对本政策有任何疑问，您可以通过本隐私政策中「如何联系我们」</span> 一节提供的联系方式与我们联系。我们会不时对隐私政策进行修订。 当本隐私政策发生实质变更时，我们会在版本更新后以推送通知、弹窗等形式向您展示变更后的内容。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                本政策部分将帮助您了解以下内容：
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                一、定义<br />
                二、我们如何收集和使用您的个人信息<br />
                <span>三、我们如何使用</span>Cookie和同类技术<br />
                四、我们如何共享、转让、公开披露您的个人信息<br />
                五、我们如何保护和保存您的个人信息<br />
                六、第三方产品或服务如何获得您的个人信息<br />
                七、您如何管理您的个人信息<br />
                八、我们如何处理未成年人的个人信息<br />
                九、本政策如何更新<br />
                十、如何联系我们
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                一、定义
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                1、守正：指研发并运营守正产品和服务的石家庄守正企业管理咨询有限公司及现在或未来设立的相关关联公司的单称或合称。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                2、守正产品及服务：指由守正科技开发和运营的专注于中老年人健康联动产品，&nbsp;<span>旨在帮助用户在信息化物联网时代提供局部智能化社区服务和专业的电梯信息对接服务、非接触式电梯或门禁操作体验。</span> 守正<span>产品的展现形式包括但不限于</span>Web页面、手机客户端、平板客户端、PC客户端、服务器服务端， 将部分功能集成或授权在我们关联公司和/或其他第三方智能硬件或软件中提供的服务。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                3、关联公司：指守正<span>现在或将来控制、受控制或与其出于共同控制下的任何公司、合法机构以及上述公司、</span> 合法机构的合法继承人。控制通过下列方式存在，包括但不限于:直接或间接的拥有超过50%的股份，或是直接或间接拥有股份虽未超过50%，但能对股东会决议施加重大影响或能够实际支配公司的行为。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                4、个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                5、个人敏感信息：<span>指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或</span> 歧视性待遇等的个人信息。<span style="color:#E53333;">在本政策中包括</span><span style="color:#E53333;">:设备信息、日志信息、通讯录信息、精确定位信息。</span>
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span style="color:#E53333;">①&nbsp;</span><span style="color:#E53333;">设备信息</span><span style="color:#E53333;">:包括设备型号、唯一设备标识符(如IMEI / Android ID / IDFA / OPENUDID | GUID | IMSI信息)、设备MAC地址、操作系统类型及型号、屏幕分辨率、电信运营商、软件安装列表、软件版本号、系统语言。</span>
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <b><span style="color:#E53333;">②&nbsp;</span></b><span style="color:#E53333;">日志信息</span><span style="color:#E53333;">:包括您在使用守正</span><span style="color:#E53333;">时发布、浏览的内容，您发布内容时的操作时间、类型、端口，访问的日期和时间，登录</span><span style="color:#E53333;">IP信息。</span>
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <b><span style="color:#E53333;">③&nbsp;</span></b><span style="color:#E53333;">通讯录信息</span><span style="color:#E53333;">:您所存储的联系人信息，包括手机号、名称。为收集上述信息，我们可能需要申请您授权我们使用设备的电话权限和存储权限。</span>
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <b><span style="color:#E53333;">④&nbsp;</span></b><span style="color:#E53333;">精准定位信息</span><span style="color:#E53333;">:指GPS或基站信息。为收集上述信息，我们需要申请您授权我们使用设备的位置权限。</span>
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                6、个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                二、我们如何收集和使用您的个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>在您使用我们的产品及</span>/或服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种:<br />
                1、为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息, 您将无法正常使用我们的产品及/或服务;<br />
                2、为实现向您提供我们产品及/或服务的扩展功能，您可选择授权我们收集、使用的信息。 如您拒绝提供，您将无法正常使用相关扩展功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (一)基本功能中我们收集和使用您个人信息的情形
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                1、完成注册
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                当您注册守正<span>产品时，为注册账号使用我们的服务</span>,您需要提供手机号码、用户姓名用于创建账户。 我们收集上述信息是为了帮助您完成守正账号的注册，如您不提供上述信息，我们将无法为您创建账户并正常向您提供服务。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span style="color:#E53333;">2、位置功能</span>
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span style="color:#E53333;">当您开启与位置有关的功能，例如发布带有位置信息的内容，我们在您开启设备定位权限后将获取设备的位置信息</span><span style="color:#E53333;">。</span><span style="color:#E53333;"> 为向您推送与您所在位置相关的内容，我们在您开启设备位置权限时会收集设备的精确定位信息。如您不同意开启设备位置权限， 我们也可能根据您的设备IP地址向您提供相关内容。</span>
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                &nbsp;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                3、保障服务的安全性和可靠性
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>为了保障服务的安全性和可靠性</span>,我们会需要收集您的设备型号、操作系统版本、唯-设备识别符(如 IMEI / MEID / android ID / IDFA / OPENUDID / IDFV / 出厂序列号, 获取IMEI部分手机会提示申请读取通话状态权限)、IP地址、浏览器类型、使用的语言、访问日期和时间、接入网络的方式、网络质量数据、服务日志信息。 我们收集您的上述信息是为了保障应用运行安全，如您不提供上述信息，则我们无法保证您在使用期间的应用运行安全。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                &nbsp;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                &nbsp;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (二)其他
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                1、您上传他人的个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                若您上传的信息中含有其他用户的个人信息，在您向守正上传这些个人信息之前，您须确保您已经取得合法的授权。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                2、超出授权同意范围使用个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                若我们将个人信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的个人信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                3、我们间接收集的个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>若我们从第三方处间接获取您的个人信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息</span>,并向您告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺。我们会使用不低于我们对直接收集个人信息同等的保护手段与措施对间接获取的个人信息进行保护。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                4、征得授权同意的例外
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意</span>:
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ① <span>与我们履行法律法规规定的义务相关的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ② <span>与国家安全、国防安全有关的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ③ <span>与公共安全、公共卫生、重大公共利益有关的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ④ <span>与刑事侦查、起诉、审判和判决执行等司法或行政执法有关的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ⑤ <span>出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ⑥ <span>您自行向社会公众公开的个人信息</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ⑦ <span>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ⑧ <span>根据与您签订和履行相关协议或其他书面文件所必需的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ⑨ 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>三、我们如何使用</span>Cookie和同类技术
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (一)Cookie
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>为确保应用</span>/网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储Cookie、FlashCookie或浏览器 (或关联应用程序)提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储(统称“Cookie” )。借助于Cookie,网站能够存储您的偏好等数据。 如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。 但如果您这么做,在某些情况下可能会影响您安全访问我们的网站，且可能需要在每一次访问我们的网站时更改用户设置，您也可通过与我们沟通的形式，取消此授权。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (二)Cookie同类技术
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ETag(实体标签)是在互联网浏览器与互联网服务器之间背后传送的HTTP协议标头，可代替Cookie。 ETag可以帮助我们避免不必要的服务器负载，提高服务效率，节省能源。 同时，我们可能通过ETag记录您的身份，以便我们可以更深入地了解和改善我们的产品或服务。 大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。 但如果停止使用Etag,您可能无法享受相对更佳的产品或服务体验，但您可通过与我们沟通的形式，取消此授权。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                四、我们如何共享、转让、公开披露您的个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (一)共享
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                我们不会与守正<span>科技以外的公司、组织和个人共享您的个人信息，但以下情况除外</span>:
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                1、在获取明确同意的情况下共享
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                获得您的明确同意后，我们会与其他方共享您的个人信息。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                2、与关联公司间共享
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                为便于我们基于守正<span>账户向您提供产品和服务，推荐您可能感兴趣的信息，您的个人信息可能会与我们的关联公司和</span>/或其指定的服务提供商共享。 我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的, 将再次征求您的授权同意。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                3、与授权合作伙伴共享
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本隐私权政策声明的合法、正当、必要、特定、明确的目的共享您的个人信息，</span> 授权合作伙伴只能接触到其履行职责所需个人信息，且不得将此信息用于其他任何目的。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>目前，我们的授权合作伙伴包括以下类型</span>:
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ① <span>广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息与提供广告、分析服务的合作伙伴共享。</span> 我们会委托这些合作伙伴处理与广告覆盖面和有效性相关的信息，但不会提供您的个人身份信息,或者我们将这些信息进行去标识化处理， 以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行我们委托的广告服务或决策建议。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ② <span>供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术基础设施服务、</span> 分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。 我们会对授权合作伙伴获取有关信息的应用程序接口(API)、 软件工具开发包(SDK)进行严格的安全检测， 并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (二)转让
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外</span>:
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ① <span>在获取明确同意的情况下转让</span>:获得您的明确同意后，我们会向其他方转让您的个人信息。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ② <span>合并、收购或破产清算</span>:在守正<span>科技发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时</span>, 如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、 组织和个人重新向您征求授权同意。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (三)公开披露
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>我们仅会在以下情况下，公开披露您的个人信息</span>:
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ① <span>获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ② 如果我们确定您出现违反法律法规或严重违反守正相关协议及规则的情况，或为保护守正<span>用户或公众的人身财产安全免遭侵害，</span> 我们可能依据法律法规或征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及守正平台对您采取的措施。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (四)共享、转让、公开披露个人信息时事先征得授权同意的例外
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意</span>:
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ① <span>与国家安全、国防安全有关的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ② <span>与公共安全、公共卫生、重大公共利益有关的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ③ <span>与刑事侦查、起诉、审判和判决执行等司法或行政执法有关的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ④ <span>出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ⑤ <span>您自行向社会公众公开的个人信息</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ⑥ 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，</span> 使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                五、我们如何保护和保存您的个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (一)我们如何保护您的个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                1、我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。 例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护; 我们同时对网站提供HTTPS协议安全浏览方式; 我们会使用加密技术提高个人信息的安全性; 我们会使用受信赖的保护机制防止个人信息遭到恶意攻击; 我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息; 以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                2、我们有行业先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系， 从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                3、在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知:安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、 您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您， 难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。 同时,我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (二)我们如何保存您的个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                1、我们将在本政策载明的目的所需的最短保存期限之内，保存您的个人信息，除非法律法规另有规定，或您向我们另行授权延长保存期限。 前述期限届满后，我们将对您的个人信息做删除或匿名化处理。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                2、我们将您的个人信息保存在中国境内。如需将您的个人信息传输至中国境外时，我们会另行征求您的同意，并遵守相关法律规定。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                六、第三方产品或服务如何获得您的个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>您同意并理解，为了更好地提供服务，我们的产品可能会集成或接入第三方服务，如</span>SDK、APl或H5等。 这些第三方服务商独立运营，单独获得您的授权同意，直接收集您的个人信息,并独立承担相关的责任和义务。 具体我们将通过使用的第三方服务以及通过第三方服务收集的个人信息如下:
            </p>
            <p class="MsoNormal" style="margin-left:35.45pt;text-indent:0pt;">
                <!--[if !supportLists]-->·&nbsp;<!--[endif]--><span>推送通知类服务</span>SDK ：
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                &nbsp;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ① 阿里云推送
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                SDK名称：阿里云SDK及第三方厂商推送SDK（小米、华为）
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                公司名称：阿里云计算有限公司
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                官网：&nbsp;https://www.aliyun.com/product/cps
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                隐私协议：<a href="http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=5176.56143.J_9220772140.78.38236358gJUN7Zguideline/jghgzy/"><u><span>《</span></u><u><span>阿里云</span></u><u><span>隐私协议》</span></u></a>&nbsp;http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=5176.56143.J_9220772140.78.38236358gJUN7Zguideline/jghgzy/
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                功能类型：消息推送服务
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>使用目的：</span>&nbsp;用于对手机终端进行房屋审核状态通知、房屋成员的审核状态通知等应用通知消息推送
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                收集数据类型：
            </p>
            <p class="MsoNormal" style="text-indent:0pt;">
                <!--[if !supportLists]-->·&nbsp;<!--[endif]--><span>设备信息（</span>IMEI/MAC/Android ID/IDFA/OAID/UAID/OpenUDID/GUID/SIM卡IMSI信息等）
            </p>
            <p class="MsoNormal" style="text-indent:0pt;">
                <!--[if !supportLists]-->·&nbsp;<!--[endif]--><span>网络信息与位置信息（</span>IP地址，WiFi信息，基站信息等相关信息）
            </p>
            <p class="MsoNormal" style="margin-left:35.45pt;text-indent:0pt;">
                <!--[if !supportLists]-->·&nbsp;<!--[endif]-->应用信息（应用崩溃信息、通知开关状态的相关信息）
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                &nbsp;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ② <span>华为推送（</span>HMS）
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                SDK名称：<span>华为推送（</span>HMS）SDK
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                公司名称：华为
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>官网：</span>https://developer.huawei.com/consumer/cn/hms/huawei-pushkit
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                隐私协议：<a href="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021"><u><span>《华为推送隐私声明》</span></u></a>
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                功能类型：用户基础服务（消息推送）
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>使用目的：</span>&nbsp;用于对华为手机终端进行房屋审核状态通知、房屋成员的审核状态通知等应用通知消息推送
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                收集数据类型：
            </p>
            <p class="MsoNormal" style="text-indent:0pt;">
                <!--[if !supportLists]-->·&nbsp;<!--[endif]-->AAID（应用匿名标识）、应用Token、Topic订阅关系
            </p>
            <p class="MsoNormal" style="text-indent:0pt;">
                <!--[if !supportLists]-->·&nbsp;<!--[endif]--><span>消息下发记录、</span>Token申请记录、显示/点击/关闭打点上报记录
            </p>
            <p class="MsoNormal" style="text-indent:0pt;">
                <!--[if !supportLists]-->·&nbsp;<!--[endif]-->缓存消息内容，根据客户端所在的大区进行数据处理
            </p>
            <p class="MsoNormal" style="margin-left:35.45pt;text-indent:0pt;">
                <!--[if !supportLists]-->·&nbsp;<!--[endif]--><span>手机用户的标识符</span>HMS Core openId，HMS Core openId将进行不可逆加密仅保存在设备端侧
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                &nbsp;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ③ 小米推送
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                SDK名称：<span>小米推送</span>SDK
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                公司名称：小米科技有限责任公司
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>官网：</span>https://dev.mi.com/console/appservice/push.html
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                隐私协议：<a href="https://dev.mi.com/console/doc/detail?pId=1822"><u><span>《小米推送隐私政策》</span></u></a>
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                https://dev.mi.com/console/doc/detail?pId=1822
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                功能类型：用户基础服务（消息推送）
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>使用目的：</span>&nbsp;用于对小米手机终端进行房屋审核状态通知、房屋成员的审核状态通知等应用通知消息推送
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                收集数据类型：
            </p>
            <p class="MsoNormal" style="margin-left:35.45pt;text-indent:0pt;">
                <!--[if !supportLists]-->·&nbsp;<!--[endif]--><span>设备标识</span>OAID和加密的Android&nbsp;ID，以及使用推送服务的应用信息如应用包名、版本号和运行状态等信息。为便于您更好地理解【小米推送SDK】个人信息手机使用规则，您可以登录或访问【小米开放平台】了解其《隐私政策》
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                &nbsp;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                七、您如何管理您的个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (一)您对您的个人信息享有以下权利
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                1、您有权访问或更正您的个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                如果您想行使个人信息访问权或更正权，您可以通过以下方式自行访问或更正：
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>（</span>1)账户信息：如果您希望访问或更正您的账户绑定的手机号、个人资料信息、更改您的密码等，您可以通过访问守正执行此类操作
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                2、您有权删除您的个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                在以下情形中，您有权向我们提出删除您的个人信息：
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>（</span>1)我们违反法律法规或与您的约定收集、使用个人信息；
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>（</span>2)我们违反法律法规或与您的约定与第三方共享或转让您的个人信息，我们将立即停止共享、转让行为，并通知第三方及时删除；
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>（</span>3)我们违反法律法规规定或与您的约定，公开披露您的个人信息，我们将立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息；
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>（</span>4)您不再使用我们的产品或服务，或您注销了账号，或我们终止服务及运营。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                3、您有权改变您授权同意的范围
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                您可以通过本隐私政策载明的方式与我们联系，以改变同意范围或撤回您的授权。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                4、您有权注销账号
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>注销功能定义</span> : 您在已注册的情况下可以完全自主决定是否注销您在守正APP的注册， 一旦您选择注销后我们将在7个工作日内根据法律法规的要求尽快删除您的个人信息或作匿名化处理。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>注销账号功能的操作路径为</span>:您可以通过联系守正运营团队客服,申请账号注销。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (二)响应您的上述请求
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>如您无法按照上述方式访问、更正或删除您的个人信息以及注销账号，您可以采取本政策载明的联系方式与我们联系。</span> 为保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份和请求的正当性，我们将在收到您反馈并验证您的身份后的15个工作日内答复您的请求。 对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。 对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (三)响应情形的例外
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>在以下情形中，我们将无法响应您的请求</span>:
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <b>①&nbsp;</b><span>与我们履行法律法规规定的义务相关的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <b>②&nbsp;</b><span>与国家安全、国防安全直接相关的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <b>③&nbsp;</b><span>与公共安全、公共卫生、重大公共利益直接相关的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <b>④&nbsp;</b><span>与刑事侦查、起诉、审判和执行判决等直接相关的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <b>⑤&nbsp;</b><span>我们有充分证据表明个人信息主体存在主观恶意或滥用权利的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <b>⑥&nbsp;</b><span>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <b>⑦&nbsp;</b><span>响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <b>⑧&nbsp;</b>涉及商业秘密的。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                八、我们如何处理未成年人的个人信息
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                守正<span>为您提供的产品或服务为集社区基础的门禁、乘梯及物业服务，我们不向未成年人提供服务。如果您为未成年人，请您不要向我们提供个人信息。</span> 如果有事实证明我们收集了未成年人的个人信息，我们在知晓后会设法尽快删除相关信息。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                九、本政策如何更新
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (一)我们的政策可能变更。未经您明确同意，我们不会限制您按照本政策所应享有的权利。&nbsp;我们会在守正APP端“我的”-“设置”一“关于”一“隐私声明”中发布对本政策所做的任何变更。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                (二)对于重大变更，我们还会提供更为显著的通知(包括以弹窗提示提醒您阅读新的隐私权政策)。本政策所指的重大变更:
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ① <span>我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ② <span>我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ③ <span>个人信息共享、转让或公开披露的主要对象发生变化</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ④ <span>您参与个人信息处理方面的权利及其行使方式发生重大变化</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ⑤ <span>我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化</span>;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                ⑥ 个人信息安全影响评估报告表明存在高风险。
            </p>
            <p>
                (三)腾讯Bugly<br />
                Bugly是为移动开发者提供专业的异常上报和运营统计，帮助开发者快速发现并解决异常的SDK，Bugly可以帮我们获取功能异常的用户的机型及异常原因。&nbsp;同时，我们可能通过Bugly记录您的身份，以便我们可以更深入地了解和改善我们的产品或服务。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                十、如何联系我们
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>如您对本政策内容有任何疑问、意见或建议，或发现个人信息可能被泄露，您可以通过客服部门与我们联系，</span> 我们将在15个工作日内回复您的请求。
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                <span>您可以将您的书面疑问、意见或建议通过以下地址寄至客服部门</span>:
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                &nbsp;
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                公司名称：石家庄守正企业管理咨询有限公司
            </p>
            <p class="MsoNormal" style="margin-left:0pt;text-indent:0pt;">
                办公地址：<span>河北省石家庄市桥西区永安街</span>46号金裕花园3-2-910
            </p>
            <p class="MsoNormal">
                联系电话：0311-85020922
            </p>
        </div>
    </div>
</template>

<script>
    // import { Toast } from 'vant';
    export default {
        name: 'agreement',
        components: {
        },
        data() {
            return {
            }},
        created() {
        },
        mounted:function(){

        },
        methods: {
        }
    }
</script>

<style scoped>
    .main{
        width: 100vw;
        min-height: 100vh;
        background-color:#ffffff;
        background-size: cover;
        box-sizing: border-box;
        position: relative;
    }
    .doc-content{
        padding: 20px;
    }
</style>
